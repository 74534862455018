import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Parse from "parse";
import Layout from "./components/main/Layout";
import Login from "./components/public/Login.js";
import Registration from "./components/public/Registration";
import FetchData from "./components/main/FetchData.js";
import Main from "./components/main/Main.js";
import Profile from "./components/main/pages/Profile/Profile.js";
import Feed from "./components/main/pages/Feed/Feed.js";
import CreatePost from "./components/main/pages/Feed/CreatePost.js";
import MatchingMain from "./components/main/pages/Matching/Main.js";
import MatchingPeople from "./components/main/pages/Matching/People.js";
import Groups from "./components/main/pages/Matching/Groups.js";
import CreateGroup from "./components/main/pages/Matching/CreateGroup.js";
import GroupInvitation from "./components/main/pages/Matching/GroupInvitation.js";
import Teams from "./components/main/pages/Matching/Teams.js";
import Chat from "./components/main/pages/Chat.js";
import Notifications from "./components/main/pages/ControlBar/Notifications.js";
import Settings from "./components/main/pages/ControlBar/Settings.js";
import GroupView from "./components/main/pages/Matching/GroupView.js";
import ChatsViewer from "./components/main/pages/ChatsViewer.js";
import HomePage from "./components/public/HomePage.js";
import FiltersPage from "./components/main/pages/Matching/CustomSearch/FiltersPage.js";
import CustomSearchPeopleResults from "./components/main/pages/Matching/CustomSearch/CustomSearchPeopleResults.js";

import { SocketProvider } from "./components/global-context/SocketContext.js";
import EditMainInfo from "./components/main/pages/ControlBar/EditMainInfo.js";
import SinglePostPage from "./components/main/pages/Feed/SinglePostPage.js";
import ResetPassword from "./components/public/ResetPassword.js";

import CommunityGuideLines from "./components/public/CommunityGuideLines.js";
import PrivacyPolicies from "./components/public/PrivacyPolicy.js";
import TermsAndConditions from "./components/public/TermsConditions";
import { Contacts } from "./components/public/Contacts.js";

function App() {
  Parse.serverURL = process.env.REACT_APP_PARSE_HOST_URL;
  Parse.initialize(
    process.env.REACT_APP_PARSE_APPLICATION_ID,
    process.env.REACT_APP_PARSE_JAVASCRIPT_KEY
  );

  return (
    <Routes>
      <Route path="/HomePage" element={<HomePage />} />
      <Route path="/login" element={<Login Parse={Parse} />} />
      <Route path="/register" element={<Registration Parse={Parse} />} />
      <Route path="/resetPassword" element={<ResetPassword Parse={Parse} />} />
      <Route path="/termsAndConditions" element={<TermsAndConditions />} />
      <Route path="/privacyPolicies" element={<PrivacyPolicies />} />
      <Route path="/communityGuideLines" element={<CommunityGuideLines />} />
      <Route path="/contacts" element={<Contacts />} />
      {/* Layout should be included only once */}
      <Route path="/" element={<Layout Parse={Parse} />}>
        <Route element={<FetchData Parse={Parse} />}>
          <Route
            path=""
            element={
              <SocketProvider>
                <Main />
              </SocketProvider>
            }
          >
            <Route index element={<Navigate to="/profile" replace />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/feed" element={<Feed />}>
              <Route path="create" element={<CreatePost />} />
              <Route path="post/:postId" element={<SinglePostPage />} />
            </Route>
            <Route path="/match" element={<MatchingMain />}>
              <Route path="people" element={<MatchingPeople />} />

              <Route
                path="groupInvitation/:receiverUserId"
                element={<GroupInvitation />}
              />
              <Route path="groups" element={<Groups />} />
              <Route path="group-detail/:groupId" element={<GroupView />} />
              <Route path="createGroup" element={<CreateGroup />} />
              <Route path="teams" element={<Teams />} />
            </Route>
            <Route path="/custom-search" element={<FiltersPage />} />
            <Route path="/chat/:roomId" element={<Chat />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/settings" element={<Settings Parse={Parse} />} />
            <Route path="/editInfo" element={<EditMainInfo />} />
            <Route path="/chats" element={<ChatsViewer />} />
            <Route
              path="/custom-search-people"
              element={<CustomSearchPeopleResults />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
