import { Divider } from "antd";
import React from "react";
import { MdArrowBack } from "react-icons/md";

export const Contacts = () => {
  return (
    <div style={{ margin: "10px" }}>
      <div className="header">
        <img
          className="header_logo"
          alt="Doinit Logo"
          src={require("../../assets/logo.png")}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            marginRight: "20px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBack size={30} />
        </div>
        <h2>Contacts</h2>
      </div>
      <Divider />
      <div className="container">
        <p>please send us an e-mail.</p>
        <p>
          <strong>Email:</strong>{" "}
          <a href="mailto:doinit.ita@gmail.com">doinit.ita@gmail.com</a>
        </p>
      </div>
    </div>
  );
};
