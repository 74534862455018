import { Divider } from "antd";
import React from "react";
import { MdArrowBack } from "react-icons/md";

export const CommunityGuideLines = () => {
  return (
    <div style={{ margin: "10px" }}>
      <div className="header">
        <img
          className="header_logo"
          alt="Doinit Logo"
          src={require("../../assets/logo.png")}
        />
      </div>

      <div className="container">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              marginRight: "20px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              window.history.back();
            }}
          >
            <MdArrowBack size={30} />
          </div>
          <h2>Community Guidelines</h2>
        </div>
        <Divider />

        <div>
          <p>
            <strong>Last updated:</strong> 2024-12-15
          </p>

          <h2>Introduction</h2>
          <p>
            At Doinit, we believe in creating a positive, supportive, and safe
            environment for all members of our community. These Community
            Guidelines are intended to ensure that everyone feels welcome and
            respected while using the platform. By engaging with Doinit, you
            agree to follow these guidelines.
          </p>

          <h2>1. Be Respectful</h2>
          <p>
            We ask that all members interact with kindness, respect, and
            empathy. Discrimination, harassment, hate speech, or bullying of any
            kind will not be tolerated.
          </p>
          <ul>
            <li>No abusive, offensive, or threatening language.</li>
            <li>
              Respect people of all backgrounds, identities, and experiences.
            </li>
            <li>Be mindful of cultural and social sensitivities.</li>
          </ul>

          <h2>2. Foster a Positive and Supportive Environment</h2>
          <p>
            Doinit is here to help individuals grow, connect, and succeed in
            their entrepreneurial journeys. Please focus on providing
            constructive feedback, offering support, and sharing knowledge that
            can help others.
          </p>
          <ul>
            <li>Offer support and encouragement to other members.</li>
            <li>
              Focus on offering solutions and advice to challenges, rather than
              just criticism.
            </li>
            <li>Maintain a helpful and constructive tone in discussions.</li>
          </ul>

          <h2>3. Maintain Privacy and Confidentiality</h2>
          <p>
            We value privacy and confidentiality. Please do not share personal
            information or confidential business details without permission.
          </p>
          <ul>
            <li>
              Do not share another member’s private information without their
              consent.
            </li>
            <li>
              Be mindful of what personal data you share publicly on the
              platform.
            </li>
            <li>
              Respect the intellectual property and confidentiality of others.
            </li>
          </ul>

          <h2>4. Avoid Spam and Irrelevant Content</h2>
          <p>
            We want to keep the community focused and relevant. Please avoid
            spamming, advertising, or posting content unrelated to the purpose
            of the platform.
          </p>
          <ul>
            <li>No unsolicited promotional content or advertisements.</li>
            <li>
              Share content that is relevant and valuable to the community.
            </li>
            <li>Refrain from posting repetitive or irrelevant messages.</li>
          </ul>
          <h2>5. Do Not Share Critical Information</h2>
          <p>
            To protect the integrity and security of your business and personal
            affairs, please refrain from sharing critical or sensitive
            information that could jeopardize the security of your or others'
            projects.
          </p>
          <ul>
            <li>
              Do not disclose sensitive data, such as passwords, financial
              details, or proprietary business plans.
            </li>
            <li>
              Avoid sharing confidential company or client information that is
              not publicly available.
            </li>
            <li>
              Respect the confidentiality of private or classified information,
              whether related to your own business or that of others.
            </li>
          </ul>
          <h2>6. No Offensive or Inappropriate Content</h2>
          <p>
            Content that is offensive, obscene, or otherwise inappropriate is
            prohibited. This includes graphic or explicit content, content that
            encourages violence, or anything that may be harmful to individuals
            or the community.
          </p>
          <ul>
            <li>Do not post offensive, harmful, or explicit content.</li>
            <li>Avoid promoting harmful behaviors or illegal activities.</li>
            <li>
              Ensure that all content shared is in line with the values of
              respect and integrity.
            </li>
          </ul>

          <h2>7. Report Violations</h2>
          <p>
            If you notice any behavior or content that violates these
            guidelines, please report it immediately. We take violations
            seriously and will take appropriate action to ensure the community
            remains safe and respectful.
          </p>
          <ul>
            <li>
              Use the platform’s reporting tools to report inappropriate content
              or behavior.
            </li>
            <li>
              We review all reports and act according to the severity of the
              issue.
            </li>
          </ul>

          <h2>8. Consequences of Violating the Guidelines</h2>
          <p>
            Violations of these community guidelines may result in temporary or
            permanent suspension of access to the platform. We will assess each
            situation on a case-by-case basis and take the necessary actions to
            protect the integrity of the community.
          </p>

          <h2>9. Changes to the Community Guidelines</h2>
          <p>
            We may update these Community Guidelines from time to time. All
            changes will be posted on this page. If significant changes are
            made, we will notify you via email or through an announcement on the
            platform.
          </p>

          <h2>10. Contact Us</h2>
          <p>
            If you have any questions or concerns regarding these Community
            Guidelines, please reach out to us at: doinit.ita@gmail.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CommunityGuideLines;
