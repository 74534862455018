import React, { useEffect, useState } from "react";
import "../../App.css";
import { Button, Divider, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { logIn } from "../global-context/Slice";
import { useDispatch, useSelector } from "react-redux";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { MdArrowBack } from "react-icons/md";

export const UserLogin = ({ Parse }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const userLogInformations = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(userLogInformations.userInfo).length > 0) {
      navigate("/");
    }
  }, [userLogInformations]);

  const doUserLogIn = async function () {
    // Note that these values come from state variables that we've declared before

    const usernameValue = username;
    const passwordValue = password;
    try {
      await Parse.User.logIn(usernameValue, passwordValue);
      const current = await Parse.User.current();
      const sessionToken = await current.getSessionToken();
      setUsername("");
      setPassword("");
      // Monitoring({
      //   id: current?.id,
      //   action: "LOGIN - NEW",
      //   additionalInfo: {},
      // });
      dispatch(logIn({ userInfo: { ...current }, sessionToken: sessionToken }));

      return true;
    } catch (error) {
      alert(`Error! ${error.message}`);
      return false;
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div style={{ margin: "10px" }}>
      <div className="header">
        <img
          className="header_logo"
          alt="Doinit Logo"
          src={require("../../assets/logo.png")}
        />
      </div>

      <div className="container">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              marginRight: "20px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              window.history.back();
            }}
          >
            <MdArrowBack size={30} />
          </div>
          <h2>Login</h2>
        </div>
        <Divider />
        <div className="form_wrapper">
          <Input
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            placeholder="Username"
            size="large"
            className="form_input"
          />
          <Input
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            placeholder="Password"
            size="large"
            type={passwordVisible ? "text" : "password"}
            className="form_input"
            suffix={
              passwordVisible ? (
                <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
              ) : (
                <EyeOutlined onClick={togglePasswordVisibility} />
              )
            }
          />
        </div>
        <div className="form_buttons">
          <Button
            onClick={() => doUserLogIn()}
            type="primary"
            className="form_button"
            size="large"
            block
          >
            Log In
          </Button>
        </div>
        <Divider />
        <p className="form__hint">
          Don't have an account?{" "}
          <a className="form__link" href="/Register">
            Sign up
          </a>
        </p>
        <p className="form__hint">
          Password forgetten ?{" "}
          <a className="form__link" href="/resetPassword">
            Reset
          </a>
        </p>
      </div>
    </div>
  );
};

export default UserLogin;
