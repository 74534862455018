import { Divider } from "antd";
import React from "react";
import { MdArrowBack } from "react-icons/md";

export const TermsAndConditions = () => {
  return (
    <div style={{ margin: "10px" }}>
      <div className="header">
        <img
          className="header_logo"
          alt="Doinit Logo"
          src={require("../../assets/logo.png")}
        />
      </div>

      <div className="container">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              marginRight: "20px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              window.history.back();
            }}
          >
            <MdArrowBack size={30} />
          </div>
          <h2>Terms and Conditions</h2>
        </div>
        <Divider />

        <div>
          <p>
            <strong>Effective Date:</strong> 2024-12-15
          </p>
          <p>
            Welcome to Doinit! These Terms and Conditions ("Terms") govern your
            access to and use of our platform, services, and any related
            applications (collectively, the "Platform"). By accessing or using
            Doinit, you agree to comply with these Terms. If you do not agree,
            please refrain from using the Platform. These Terms may be modified
            or updated periodically to reflect changes in laws, regulations, or
            the services offered by the platform. We encourage you to regularly
            review this page to stay informed of any changes. Your continued use
            of the Doinit platform after updates are posted constitutes
            acceptance of the revised Terms.
          </p>

          <section>
            <h2>1. Acceptance of Terms</h2>
            <p>
              By creating an account or using Doinit in any capacity, you
              confirm that you:
            </p>
            <ul>
              <li>
                You are an individual (i.e., not any body corporate, partnership
                or other business entity) at least 18 years old;
              </li>
              <li>
                You are legally qualified to enter a binding contract with
                Doinit;
              </li>
              <li>You are not prohibited by law from using our Services;</li>
              <li>
                You have not committed, been convicted of, or pled no contest to
                a felony or indictable offense (or crime of similar severity), a
                sex crime, or any crime involving violence or a threat of
                violence, unless you have received clemency for a non-violent
                crime and we have determined that you are not likely to pose a
                threat to other users of our Services;
              </li>
              <li>
                You are not required to register as a sex offender with any
                state, federal or local sex offender registry;
              </li>
              <li>
                You have not previously been removed from our Services or our
                affiliates’ services by us or our affiliates, unless you have
                our express written permission to create a new account;
              </li>
              <li>You do not have more than one account on our Services;</li>
              <li>
                Have read, understood, and agree to these Terms and our Privacy
                Policy;
              </li>
              <li>
                Will comply with all applicable laws and regulations while using
                the Platform;
              </li>
            </ul>
            <p>
              If at any time you cease to meet these requirements, all
              authorization to access our Services or systems is automatically
              revoked, and you must immediately delete your account, and we
              retain the right to remove your access to our Services without
              warning.
            </p>
          </section>

          <section>
            <h2>2. Use of the Platform</h2>
            <p>You agree to:</p>
            <ul>
              <li>Use Doinit for lawful purposes only.</li>
              <li>
                Comply with all applicable laws, including without limitation,
                privacy laws, intellectual property laws, anti-spam laws, and
                regulatory requirements
              </li>
              <li>Not engage in fraudulent, abusive, or harmful activities.</li>
              <li>
                Take reasonable measures to protect the security of your login
                information
              </li>
              <li>Use the latest version of the Website and/or App;</li>
              <li>
                Provide accurate and complete information when creating your
                account.
              </li>
              <li>
                Refrain from attempting to gain unauthorized access to other
                users' accounts or the Platform's systems.
              </li>
            </ul>
            <p>
              Doinit reserves the right to suspend or terminate your account if
              you violate these Terms.
            </p>
          </section>

          <section>
            <h2>3. Account Responsibilities</h2>
            <p>You are responsible for:</p>
            <ul>
              <li>
                Maintaining the confidentiality of your account credentials.
              </li>
              <li>All activities under your account.</li>
              <li>
                Informing us immediately of any unauthorized use of your
                account.
              </li>
              <li>Ensuring your account information is up-to-date.</li>
            </ul>
            <p>
              Doinit is not responsible for any loss or damage arising from your
              failure to secure your account credentials.
            </p>
          </section>

          <section>
            <h2>4. Community Guidelines</h2>
            <p>To ensure a positive experience for all users, you agree to:</p>
            <ul>
              <li>Treat others with respect and kindness.</li>
              <li>
                Not share or post harmful, offensive, illegal, or inappropriate
                content.
              </li>
              <li>
                Refrain from spamming, harassing, or misusing messaging
                features.
              </li>
              <li>
                Comply with our detailed community interaction guidelines,
                available <a href="/communityGuideLines">here</a>.
              </li>
            </ul>
            <p>
              Failure to adhere to these guidelines may result in account
              suspension or termination.
            </p>
          </section>

          <section>
            <h2>5. Virtual Coins</h2>
            <p>
              Doinit rewards user engagement with virtual Coins ("Coins"). These
              Coins:
            </p>
            <ul>
              <li>
                Have no monetary value and cannot be exchanged for cash or
                transferred to other users.
              </li>
              <li>
                Can only be used within the Platform for specific features or
                services.
              </li>
              <li>
                May expire or be revoked if your account is suspended,
                terminated, or inactive for an extended period.
              </li>
            </ul>
          </section>

          <section>
            <h2>6. Intellectual Property</h2>
            <p>
              All content, trademarks, logos, and features of Doinit are the
              property of Salvatore Pennisi or its licensors. You may not copy,
              reproduce, modify, or distribute any part of the Platform without
              prior written consent.
            </p>
            <p>
              Your contributions to the Platform (e.g., posts, comments) remain
              your property, but by submitting them, you grant Doinit a
              non-exclusive, worldwide, royalty-free license to use, modify, and
              display this content within the Platform.
            </p>
          </section>

          {/* Add other sections following this structure */}

          <footer>
            <h2>Contact Us</h2>
            <p>
              If you have questions or concerns about these Terms, please
              contact us at:
            </p>
            <p>
              <strong>Email:</strong> doinit.ita@gmail.com
            </p>
            <p>
              <strong>Point of contact :</strong> Salvatore Pennisi
            </p>
            <p>
              <strong>Address:</strong> Via Angelo Musco n. 20, Milo, Catania,
              95010, Italy.
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
