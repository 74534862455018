import { Divider } from "antd";
import React from "react";
import { MdArrowBack } from "react-icons/md";

export const PrivacyPolicies = () => {
  return (
    <div style={{ margin: "10px" }}>
      <div className="header">
        <img
          className="header_logo"
          alt="Doinit Logo"
          src={require("../../assets/logo.png")}
        />
      </div>

      <div className="container">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              marginRight: "20px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              window.history.back();
            }}
          >
            <MdArrowBack size={30} />
          </div>
          <h2>Privacy Policies</h2>
        </div>
        <Divider />

        <div>
          <p>
            <strong>Last updated:</strong> 2024-12-15
          </p>

          <h2>Introduction</h2>
          <p>
            This Privacy Policy is designed to inform you about how we collect,
            use, store, and protect your personal data when you access and use
            the Doinit platform (hereinafter referred to as "the Platform"). By
            using the Platform, you agree to the practices described in this
            Privacy Policy.
          </p>

          <h2>1. Information We Collect</h2>
          <p>We collect the following categories of information:</p>
          <ul>
            <li>
              <strong>Personal Information:</strong> name, email, phone number,
              date of birth, and other information provided during registration
              or interaction with the Platform.
            </li>
            <li>
              <strong>Usage Information:</strong> data related to your access
              and use of the Platform, such as IP addresses, device types,
              browsing sessions, and interactions with content.
            </li>
            <li>
              <strong>Payment Data:</strong> if you make purchases, we collect
              payment information through third-party payment processors (e.g.,
              Stripe or PayPal), who are responsible for handling and protecting
              your payment data.
            </li>
            <li>
              <strong>User-Generated Content:</strong> comments, posts,
              messages, and other interactions created by you within the
              Platform.
            </li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>We use the information we collect for the following purposes:</p>
          <ul>
            <li>To provide and improve the services on the Platform.</li>
            <li>To manage your account and registration.</li>
            <li>
              To communicate with you, respond to your inquiries, and send
              important updates.
            </li>
            <li>To personalize your experience on the Platform.</li>
            <li>To analyze Platform usage to improve its functionality.</li>
            <li>
              To send marketing communications (if you have opted in to receive
              them).
            </li>
            <li>To process payments and transactions.</li>
          </ul>

          <h2>3. Sharing Your Information</h2>
          <p>
            We do not sell, trade, or transfer your personal data to third
            parties except in the following cases:
          </p>
          <ul>
            <li>
              <strong>Service Providers:</strong> We work with service providers
              who assist us in managing the Platform (e.g., hosting, customer
              support, payment processing). These service providers are
              obligated to protect your data and use it only for service-related
              purposes.
            </li>
            <li>
              <strong>Legal Obligations:</strong> We may disclose your data if
              required by law or to protect the rights, safety, or property of
              Doinit, users, or third parties.
            </li>
          </ul>

          <h2>4. Data Security</h2>
          <p>
            We implement security measures to protect your personal data,
            including encryption and restricted access to data. However, no
            method of data transmission over the Internet or electronic storage
            is completely secure, and we cannot guarantee the absolute security
            of your data.
          </p>

          <h2>5. Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access, correct, or delete your personal data.</li>
            <li>Object to the processing of your data in certain cases.</li>
            <li>Request the limitation of the processing of your data.</li>
            <li>
              Withdraw consent at any time (without affecting the legality of
              processing based on consent before its withdrawal).
            </li>
          </ul>
          <p>
            To exercise these rights, you can contact us at the email address
            provided on our website.
          </p>

          <h2>6. Cookies</h2>
          <p>
            The Platform uses cookies to enhance the user experience. You can
            manage cookie preferences through your browser settings.
          </p>

          <h2>7. Data Retention</h2>
          <p>
            We retain your personal data for as long as necessary to fulfill the
            purposes for which it was collected, unless a longer retention
            period is required by law.
          </p>

          <h2>8. Changes to the Privacy Policy</h2>
          <p>
            We reserve the right to update this Privacy Policy at any time.
            Changes will be posted on this page, and if applicable, we will
            notify you via email or through a notification on the Platform. We
            encourage you to review this page regularly to stay informed about
            any changes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicies;
