import React, { useEffect, useState } from "react";
import "../../App.css";
import { Button, Divider, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { logIn } from "../global-context/Slice";
import { useDispatch, useSelector } from "react-redux";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { MdArrowBack } from "react-icons/md";

export const ResetPassword = ({ Parse }) => {
  const [email, setEmail] = useState("");

  const checkEmailExistence = async () => {
    try {
      const query = new Parse.Query(Parse.User);
      query.equalTo("email", email);
      const user = await query.first();
      return !!user;
    } catch (error) {
      alert(`Something went wrong during the email check`);
    }
  };

  const doRequestPasswordReset = async function () {
    const emailExists = await checkEmailExistence();
    if (emailExists) {
      try {
        await Parse.User.requestPasswordReset(email);
        alert(
          `Success! Please check ${email} email box to proceed with password reset.`
        );
      } catch (error) {
        alert(`Error! ${error}`);
      }
    } else {
      alert(`The inserted email does not exists`);
    }
  };

  return (
    <div style={{ margin: "10px" }}>
      <div className="header">
        <img
          className="header_logo"
          alt="Doinit Logo"
          src={require("../../assets/logo.png")}
        />
      </div>

      <div className="container">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              marginRight: "20px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              window.history.back();
            }}
          >
            <MdArrowBack size={30} />
          </div>
          <h2>Reset</h2>
        </div>
        <Divider />
        <div className="form_wrapper">
          <Input
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder="Your email addres"
            size="large"
            className="form_input"
          />
        </div>
        <div className="form_buttons">
          <Button
            onClick={() => doRequestPasswordReset()}
            type="primary"
            className="form_button"
            size="large"
            block
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
